<template>
  <div class="taxReportYearly">
    <h1>税金月报表</h1>
    <el-form :inline="true">
      <el-form-item label="年份">
        <el-date-picker :clearable="false" v-model="searchParam.year" type="year" style="width: 110px;" />
      </el-form-item>
      <el-form-item>
        <el-button-group>
          <el-button type="primary" @click="commitFindList()">搜索</el-button>
          <el-button @click="resetSearchParam()">重置</el-button>
        </el-button-group>
      </el-form-item>
    </el-form>
    <el-table :data="data.list" border show-summary :summary-method="summary" height="calc(100vh - 310px)">
      <el-table-column label="日期" width="180" show-overflow-tooltip>
        <template #default="scope">
          <el-text>{{ scope.row.dateGe + '~' + scope.row.dateLe }}</el-text>
        </template>
      </el-table-column>
      <el-table-column prop="saleQuantity" label="数量" show-overflow-tooltip align="right">
        <template #default="scope">
          {{ math.formatNumber(scope.row.saleQuantity) }}
        </template>
      </el-table-column>
      <el-table-column prop="salePriceZengzhiTax" label="增值税" show-overflow-tooltip align="right">
        <template #default="scope">
          {{ math.formatNumber(scope.row.salePriceZengzhiTax) }}
        </template>
      </el-table-column>
      <el-table-column prop="salePriceFujiaTax" label="附加税" show-overflow-tooltip align="right">
        <template #default="scope">
          {{ math.formatNumber(scope.row.salePriceFujiaTax) }}
        </template>
      </el-table-column>
      <el-table-column prop="salePriceYinhuaTax" label="印花税" show-overflow-tooltip align="right">
        <template #default="scope">
          {{ math.formatNumber(scope.row.salePriceYinhuaTax) }}
        </template>
      </el-table-column>
      <el-table-column prop="salePriceSuodeTax" label="所得税" show-overflow-tooltip align="right">
        <template #default="scope">
          {{ math.formatNumber(scope.row.salePriceSuodeTax) }}
        </template>
      </el-table-column>
      <el-table-column prop="sumSalePriceTax" label="税金合计" show-overflow-tooltip align="right">
        <template #default="scope">
          {{ math.formatNumber(scope.row.sumSalePriceTax) }}
        </template>
      </el-table-column>
      <el-table-column prop="zengzhiTaxBurden" label="增值税税负" show-overflow-tooltip align="right">
        <template #default="scope">
          <el-text>{{ scope.row.zengzhiTaxBurden ? (math.halfUp(scope.row.zengzhiTaxBurden * 100, 2) + '%') : '-' }}</el-text>
        </template>
      </el-table-column>
    </el-table>
    <div style="height: 10px;" />
  </div>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import api from '../../apis/api'
import util from '../../utils/util'
import math from '../../utils/math'

const data = reactive({
  list: [],
})

const show = reactive({
  selected: [],
})

const searchParam = reactive({})
const resetSearchParam = () => {
  searchParam.year = new Date()
}
resetSearchParam()

const commitFindList = () => {
  if (!searchParam.year) {
    ElMessage.error('请选择年份')
    return
  }
  data.list = []
  const params = {}
  const firstDay = new Date(searchParam.year.getFullYear() - 1, 11, 25)
  const lastDay = new Date(searchParam.year.getFullYear(), 11, 24)
  for (const date = firstDay; date <= lastDay; date.setMonth(date.getMonth() + 1)) {
    params.dateGe = util.parseTime(date, '{y}-{m}-{d}')
    const dateLe = new Date(date)
    dateLe.setMonth(date.getMonth() + 1)
    dateLe.setDate(date.getDate() - 1)
    params.dateLe = util.parseTime(dateLe, '{y}-{m}-{d}')
    api.get('/backend/statistics/taxReport', { params: params }).then(res => {
      data.list.push(res)
      data.list.sort((a, b) => {
        return new Date(a.dateGe).getTime() - new Date(b.dateGe).getTime()
      })
    })
  }
}

const summary = () => {
  const list = data.list
  let saleQuantity = 0
  let saleAmountUntax = 0
  let saleAmountZengzhiTax = 0
  let saleAmountFujiaTax = 0
  let saleAmountYinhuaTax = 0
  let saleAmountSuodeTax = 0
  let sumSaleAmountTax = 0
  list.forEach(item => {
    saleQuantity = math.plus(saleQuantity, item.saleQuantity)
    saleAmountUntax = math.plus(saleAmountUntax, item.saleAmountUntax)
    saleAmountZengzhiTax = math.plus(saleAmountZengzhiTax, item.saleAmountZengzhiTax)
    saleAmountFujiaTax = math.plus(saleAmountFujiaTax, item.saleAmountFujiaTax)
    saleAmountYinhuaTax = math.plus(saleAmountYinhuaTax, item.saleAmountYinhuaTax)
    saleAmountSuodeTax = math.plus(saleAmountSuodeTax, item.saleAmountSuodeTax)
    sumSaleAmountTax = math.plus(sumSaleAmountTax, item.sumSaleAmountTax)
  })
  let salePriceZengzhiTax = 0
  let salePriceFujiaTax = 0
  let salePriceYinhuaTax = 0
  let salePriceSuodeTax = 0
  let sumSalePriceTax = 0
  let zengzhiTaxBurden = 0
  if (saleQuantity != 0) {
    salePriceZengzhiTax = math.halfUp(saleAmountZengzhiTax / saleQuantity, 2)
    salePriceFujiaTax = math.halfUp(saleAmountFujiaTax / saleQuantity, 2)
    salePriceYinhuaTax = math.halfUp(saleAmountYinhuaTax / saleQuantity, 2)
    salePriceSuodeTax = math.halfUp(saleAmountSuodeTax / saleQuantity, 2)
    sumSalePriceTax = math.halfUp(sumSaleAmountTax / saleQuantity, 2)
  }
  if (saleAmountUntax != 0) {
    zengzhiTaxBurden = math.halfUp(saleAmountZengzhiTax / saleAmountUntax, 4)
  }
  return ['合计',
    math.formatNumber(saleQuantity),
    math.formatNumber(salePriceZengzhiTax),
    math.formatNumber(salePriceFujiaTax),
    math.formatNumber(salePriceYinhuaTax),
    math.formatNumber(salePriceSuodeTax),
    math.formatNumber(sumSalePriceTax),
    math.halfUp(zengzhiTaxBurden * 100, 2) + '%'
  ]
}
</script>

<style lang="less"></style>