<template>
  <div class="saleProfitReportYearly">
    <h1>利润月报表</h1>
    <el-form :inline="true">
      <el-form-item label="年份">
        <el-date-picker :clearable="false" v-model="searchParam.year" type="year" style="width: 110px;" />
      </el-form-item>
      <el-form-item>
        <el-button-group>
          <el-button type="primary" @click="commitFindList()">搜索</el-button>
          <el-button @click="resetSearchParam()">重置</el-button>
        </el-button-group>
      </el-form-item>
    </el-form>
    <el-table :data="data.list" border show-summary :summary-method="summary" height="calc(100vh - 310px)">
      <el-table-column label="日期" width="180" show-overflow-tooltip>
        <template #default="scope">
          <el-text>{{ scope.row.dateGe + '~' + scope.row.dateLe }}</el-text>
        </template>
      </el-table-column>
      <el-table-column prop="saleQuantity" label="销售数量" show-overflow-tooltip align="right">
        <template #default="scope">
          <el-text>{{ math.formatNumber(scope.row.saleQuantity) }}</el-text>
        </template>
      </el-table-column>
      <el-table-column prop="salePrice" label="销售价" show-overflow-tooltip align="right">
        <template #default="scope">
          <el-text>{{ math.formatNumber(scope.row.salePrice) }}</el-text>
        </template>
      </el-table-column>
      <el-table-column prop="procurePrice" label="采购价" show-overflow-tooltip align="right">
        <template #default="scope">
          <el-text>{{ math.formatNumber(scope.row.procurePrice) }}</el-text>
        </template>
      </el-table-column>
      <el-table-column prop="procureTransportPrice" label="上游运费" show-overflow-tooltip align="right">
        <template #default="scope">
          <el-text>{{ math.formatNumber(scope.row.procureTransportPrice) }}</el-text>
        </template>
      </el-table-column>
      <el-table-column prop="saleTransportPrice" label="下游运费" show-overflow-tooltip align="right">
        <template #default="scope">
          <el-text>{{ math.formatNumber(scope.row.saleTransportPrice) }}</el-text>
        </template>
      </el-table-column>
      <el-table-column prop="dispatchPrice" label="调度费" show-overflow-tooltip align="right">
        <template #default="scope">
          <el-text>{{ math.formatNumber(scope.row.dispatchPrice) }}</el-text>
        </template>
      </el-table-column>
      <el-table-column prop="sumSalePriceEstimatedCostPrice" label="预估费用" show-overflow-tooltip align="right">
        <template #default="scope">
          <el-text>{{ math.formatNumber(scope.row.sumSalePriceEstimatedCostPrice) }}</el-text>
        </template>
      </el-table-column>
      <el-table-column prop="sumSalePriceTax" label="合计税金" show-overflow-tooltip align="right">
        <template #default="scope">
          <el-text>{{ math.formatNumber(scope.row.sumSalePriceTax) }}</el-text>
        </template>
      </el-table-column>
      <el-table-column prop="saleProfitPrice" label="单位利润" show-overflow-tooltip align="right">
        <template #default="scope">
          <el-text>{{ math.formatNumber(scope.row.saleProfitPrice) }}</el-text>
        </template>
      </el-table-column>
      <el-table-column prop="saleProfitAmount" label="利润金额" show-overflow-tooltip align="right">
        <template #default="scope">
          <el-text>{{ math.formatNumber(scope.row.saleProfitAmount) }}</el-text>
        </template>
      </el-table-column>
    </el-table>
    <div style="height: 10px;" />
  </div>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import api from '../../apis/api'
import util from '../../utils/util'
import math from '../../utils/math'

const data = reactive({
  list: [],
})

const show = reactive({
  selected: [],
})

const searchParam = reactive({})
const resetSearchParam = () => {
  searchParam.year = new Date()
}
resetSearchParam()

const commitFindList = () => {
  if (!searchParam.year) {
    ElMessage.error('请选择年份')
    return
  }
  data.list = []
  const params = {}
  const firstDay = new Date(searchParam.year.getFullYear() - 1, 11, 25)
  const lastDay = new Date(searchParam.year.getFullYear(), 11, 24)
  for (const date = firstDay; date <= lastDay; date.setMonth(date.getMonth() + 1)) {
    params.dateGe = util.parseTime(date, '{y}-{m}-{d}')
    const dateLe = new Date(date)
    dateLe.setMonth(date.getMonth() + 1)
    dateLe.setDate(date.getDate() - 1)
    params.dateLe = util.parseTime(dateLe, '{y}-{m}-{d}')
    api.get('/backend/statistics/saleProfitReport', { params: params }).then(res => {
      data.list.push(res)
      data.list.sort((a, b) => {
        return new Date(a.dateGe).getTime() - new Date(b.dateGe).getTime()
      })
    })
  }
}

const summary = () => {
  const list = data.list
  let saleQuantity = 0
  let saleAmount = 0
  let procureAmount = 0
  let procureTransportAmount = 0
  let saleTransportAmount = 0
  let dispatchAmount = 0
  let sumSalePriceEstimatedCost = 0
  let sumSaleAmountTax = 0
  let saleProfitAmount = 0
  list.forEach(item => {
    saleQuantity = math.plus(saleQuantity, item.saleQuantity)
    saleAmount = math.plus(saleAmount, item.saleAmount)
    procureAmount = math.plus(procureAmount, item.procureAmount)
    procureTransportAmount = math.plus(procureTransportAmount, item.procureTransportAmount)
    saleTransportAmount = math.plus(saleTransportAmount, item.saleTransportAmount)
    dispatchAmount = math.plus(dispatchAmount, item.dispatchAmount)
    sumSalePriceEstimatedCost = math.plus(sumSalePriceEstimatedCost, item.sumSalePriceEstimatedCost)
    sumSaleAmountTax = math.plus(sumSaleAmountTax, item.sumSaleAmountTax)
    saleProfitAmount = math.plus(saleProfitAmount, item.saleProfitAmount)
  })
  let salePrice = 0
  let procurePrice = 0
  let procureTransportPrice = 0
  let saleTransportPrice = 0
  let dispatchPrice = 0
  let sumSalePriceEstimatedCostPrice = 0
  let sumSalePriceTax = 0
  let saleProfitPrice = 0
  if (saleQuantity != 0) {
    salePrice = math.halfUp(saleAmount / saleQuantity, 2)
    procurePrice = math.halfUp(procureAmount / saleQuantity, 2)
    procureTransportPrice = math.halfUp(procureTransportAmount / saleQuantity, 2)
    saleTransportPrice = math.halfUp(saleTransportAmount / saleQuantity, 2)
    dispatchPrice = math.halfUp(dispatchAmount / saleQuantity, 2)
    sumSalePriceEstimatedCostPrice = math.halfUp(sumSalePriceEstimatedCost / saleQuantity, 2)
    sumSalePriceTax = math.halfUp(sumSaleAmountTax / saleQuantity, 2)
    saleProfitPrice = math.halfUp(saleProfitAmount / saleQuantity, 2)
  }
  return ['合计',
    math.formatNumber(saleQuantity),
    math.formatNumber(salePrice),
    math.formatNumber(procurePrice),
    math.formatNumber(procureTransportPrice),
    math.formatNumber(saleTransportPrice),
    math.formatNumber(dispatchPrice),
    math.formatNumber(sumSalePriceEstimatedCostPrice),
    math.formatNumber(sumSalePriceTax),
    math.formatNumber(saleProfitPrice),
    math.formatNumber(saleProfitAmount)
  ]
}
</script>

<style lang="less"></style>